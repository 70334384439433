<template>
  <div class="content">
    
	<el-row class="bgW">
	  <el-col :span="22">
	    <!--检索条件-->
	    <el-form :inline="true" size="mini" :model="search">
	      <el-form-item label="日期">
	        <el-date-picker
	          v-model="value1"
	          type="daterange"
	          range-separator="至"
	          class="w300"
	          start-placeholder="开始日期"
	          end-placeholder="结束日期"
	          value-format="yyyy-MM-dd"
	          @change="searchTime"
	        ></el-date-picker>
	      </el-form-item>
	      
	      <el-form-item label="门店/门诊" v-if="!userInfo.shop_id">
          <el-select
            v-model="search.shopId"
            clearable
            remote
            filterable
            :remote-method="remoteMethod"
            placeholder="请输入店铺名称"
            class="w120 mr20"
          >
            <el-option
              v-for="item in options.shopId"
              :key="item.shopId"
              :label="item.shopName"
              :value="item.shopId"
            />
          </el-select>
        </el-form-item>
		  
        <el-form-item label="员工">
          <el-input
            v-model="search.nurse_name"
            maxlength="30"
            class="w100"
            placeholder="请输入"
            clearable
          />
        </el-form-item>

        <el-form-item label="状态">
            <el-select v-model="search.status" clearable class="w140" placeholder="请选择统状态">
              <el-option
                v-for="item in options.status"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
	      
	    </el-form>
	  </el-col>
	  <el-col :span="2" style="text-align: right;">
	    <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
	  </el-col>
	</el-row>
	
    <!--列表-->
    <el-row class="mt30">
      <div class="btnBox">
        <el-button
          type="primary"
          class="mb10"
          size="mini"
          v-if="!userInfo.shop_id"
          @click="exportData('export')"
        >导出数据</el-button>
      </div>
      <el-col :span="24">
        <el-table
          :data="list"
          size="small"
          border
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
        >
          <ElTableColumn label="市场" prop="market_name" width='140'/>
		      <ElTableColumn label="门店" prop="shop_name" width='100'/>
          <ElTableColumn label="员工姓名" prop="name" />
          <ElTableColumn label="等级" prop="station_name" />
          <ElTableColumn label="疤痘护理" prop="bahennum" />
          <ElTableColumn label="面部护理" prop="mianbunum" />
          <ElTableColumn label="项目身体护理" prop="shentinum" />
          <ElTableColumn label="生命之源" prop="shengming"/>
          <ElTableColumn label="经络通" prop="jingluotong" />
          <ElTableColumn label="疏肝理气" prop="shuganliqi"/>
          <ElTableColumn label="基础补水" prop="jichubushui" />
          <ElTableColumn label="护理总数" prop="total" />
		  <ElTableColumn label="护理人数(当天)" prop="daycount" />
        </el-table>
        <pagination
          class="page tc mt10"
          :total="page.total"
          :page.sync="page.page"
          :limit.sync="page.limit"
          @pagination="getList"
        />
      </el-col>
    </el-row>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getShopList } from "@/api/shop";
import pagination from "@/components/Pagination";
import { serviceNursing } from "@/api/client";

class Search {
  start_time = ""; // 开始日期
  endTime = ""; // 结束日期
  //customer_name = ""; // 客户名称
  //telephone = ""; // 客户电话
  nurse_name = "";//护理师
  status = ""; // 状态
  shopId = "";
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}

class Options {
  constructor() {
    this.shopId = []; // 店铺
    this.status = [
      { id: 1, name: "激活" },
      { id: 2, name: "禁用" },
      { id: 3, name: "离职" }
    ];
  }
  
}

export default {
  name: "serviceNursing",
  components: {
    pagination
  },
  props: {
    clienId: {
      type: String,
      default: ""
    },
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      search: new Search(),
      //verifyDetail: new VerifyDetail(),
      options: new Options(),
      //rules: new Rules(),
      page: new Page(), // 分页
      list: [], // 数据展示
      
      timeValue: [],
      value1: [],
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        }
      }
    };
  },

  mounted() {
    this.getList();
  },

  computed: {
    ...mapGetters(["userInfo"])
  },
  

  methods: {
    // 获取列表
    getList(option) {
      if (option === "search") this.page = new Page();
      serviceNursing({
        ...this.search,
        customer_id: this.clienId,
        ...this.page
      }).then(res => {
        this.list = res.service_log;
        this.page.total = res.dataCount;
      });
     // this.getStaff();
    },

    // 导出
    exportData(option){
      serviceNursing({
        ...this.search,
        customer_id: this.clienId,
        ...this.page,
        export : 1
      }).then(res => {
        window.open(res.url);
      });
      // this.getStaff();
    },

    // 客户所在店铺员工列表
    getStaff() {
      staffList({ page: 1, limit: 1000000, shopId: this.detail.shop_id }).then(
        res => {
          this.options.sale_id = res.list;
        }
      );
    },
  
    // 服务日期搜索
    searchTime(val) {
      if (val) {
        this.search.start_time = val[0];
        this.search.endTime = val[1];
      } else {
        this.search.start_time = "";
        this.search.endTime = "";
        this.getList("search");
      }
    },
    
    // 店铺查找
    remoteMethod(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shopId = res.list;
      });
    },
   
  }
};
</script>

<style lang="scss" scoped>
</style>
